/* configuration css classes for tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;
/*  Dashboard root styles */
:root {
	/* Colors: */
	--unnamed-color-3ae374: #3ae374;
	--unnamed-color-ff9f1a: #ff9f1a;
	--unnamed-color-ff3838: #ff3838;
	--unnamed-color-989898: #989898;
	--unnamed-color-9ED1B8: #9ed1b8;
	--unnamed-color-f6f6f6: #f6f6f6;
	--unnamed-color-1a5fff: #1a5fff;
	--unnamed-color-c6c6c6: #c6c6c6;
	--unnamed-color-707070: #707070;
	--unnamed-color-095048: #5d305fff;
	--unnamed-color-119184: #30506bff;
	--text: #200407;
	--backgorund: #fdf1f3;
	--unnamed-color-fff783: #fff783;
	--unnamed-color-fffbc1: #fffbc1;
	--unnamed-color-b2a704: #b2a704;
	--unnamed-color-ffde06: #30506bff;
	--unnamed-color-ff92a5: #ff92a5;
	--unnamed-color-846E85ff: #846e85ff;
	--unnamed-color-a1182f: #a1182f;
	--unnamed-color-e62237: #f6f6f6;
	--unnamed-color-ffffff: #ffffff;

	/* Font/text values */
	--unnamed-font-family-tajawal: Tajawal;
	--unnamed-font-style-normal: normal;
	--unnamed-font-weight-medium: medium;
	--unnamed-font-weight-normal: normal;
	--unnamed-font-size-18: 18px;
	--unnamed-font-size-20: 20px;
	--unnamed-character-spacing-0: 0px;
	--unnamed-line-spacing-33: 33px;

	/* box shadow */
	--dashboard-routes-wights-shadow: 0px 3px 6px #00000029;
	--section-shadow: 0px 3px 6px #5D305Fff35;
	--create-program-box-shadow: 0px 3px 6px #0000000f;
	--question_content_shadow: 0px 3px 6px #5D305Fff4b;
}
/* Global styles */
* {
	margin: 0;
	padding: 0;
	font-family: "Tajawal", sans-serif !important;
}
html,
body,
#root {
	font-family: "Tajawal", sans-serif !important;
	scroll-behavior: smooth;
}

body:has(.MuiPopover-root) {
	overflow: auto !important;
	padding-right: 0 !important;
}
body:has(.hidden_body_scroll) {
	overflow: hidden !important;
}

header {
	padding-right: 0 !important;
}

/* App Container */
.app_container {
	width: 75%;
	margin: 0 auto;
}

input,
input:hover,
input:focus,
textarea {
	border: 0;
	outline: none;
}

/* ---> Svg <--- */
svg circle {
	fill: transparent;
}

svg.twitter-icon circle {
	fill: inherit;
}

svg.save-icon path,
svg.close-icon-green path,
.add-icon path {
	fill: var(--unnamed-color-119184);
}
svg.preview-video-icon circle,
svg.link-icon,
svg.programBlogIcon path,
svg.google-link-icon path {
	fill: var(--unnamed-color-095048);
}
svg.packages-icon path,
svg.add_more_question_icon path {
	fill: #200407;
}
svg.discount_icon_in_register_form g g path {
	fill: var(--unnamed-color-095048);
}
svg.discount_icon_in_register_form g g line {
	stroke: var(--unnamed-color-095048);
}
svg.close-icon-white,
svg.ok-icon path {
	fill: #fff;
}
svg.edit-question path {
	fill: #200407;
}
svg.blog-icon path,
svg.open-eye path {
	fill: #c6c6c6;
}
svg.sort-icon {
	color: var(--unnamed-color-095048);
	width: 24px;
	height: 24px;
	stroke-width: 1;
}
svg.blog_icon_inBlog_page path {
	fill: #200407;
}
svg.create-blg-icon path {
	fill: #fff;
}
svg.write-icon path {
	fill: #adb5b9;
}
/* --------------------- style scroll bar --------------------- */
::-webkit-scrollbar {
	width: 8px;
}

/* The scrollbar thumb */
::-webkit-scrollbar-thumb {
	background: var(--unnamed-color-119184);
	border-radius: 6px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

/*  custom scroll style for add new question section */
.question_content_scroll::-webkit-scrollbar,
.question_title_scroll::-webkit-scrollbar {
	width: 6px;
}

.question_content_scroll::-webkit-scrollbar-thumb,
.question_title_scroll::-webkit-scrollbar-thumb {
	background: var(--unnamed-color-095048);
	border-radius: 100px;
	-webkit-border-radius: 100px;
	-moz-border-radius: 100px;
	-ms-border-radius: 100px;
	-o-border-radius: 100px;
}

/* The scrollbar thumb */
.question_content_scroll::-webkit-scrollbar-thumb:hover,
.question_title_scroll::-webkit-scrollbar-thumb:hover {
	background: var(--unnamed-color-095048);
}

.question_content_scroll::-webkit-scrollbar-track,
.question_title_scroll::-webkit-scrollbar-track {
	background: transparent;
}

/* ------------------------------------------------------------- */
/* Dashboard content styles */
.dashboard-content {
	width: calc(100% - 290px);
	position: fixed;
	left: 0;
	height: 100vh;
	overflow-y: auto;
	top: 90px;
	padding-bottom: 100px;
}

/* Character Styles */
.character-style-1 {
	font-family: var(--unnamed-font-family-tajawal);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-medium);
	font-size: var(--unnamed-font-size-20);
	line-height: var(--unnamed-line-spacing-33);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--text);
}
.character-style-2 {
	font-family: var(--unnamed-font-family-tajawal);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-normal);
	font-size: var(--unnamed-font-size-18);
	line-height: var(--unnamed-line-spacing-33);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--text);
}
.character-style-3 {
	font-family: var(--unnamed-font-family-tajawal);
	font-style: var(--unnamed-font-style-normal);
	font-weight: var(--unnamed-font-weight-medium);
	font-size: var(--unnamed-font-size-18);
	line-height: var(--unnamed-line-spacing-33);
	letter-spacing: var(--unnamed-character-spacing-0);
	color: var(--text);
}

/* dashboard routes wights style */
.dashboard-routes-wights-style {
	box-shadow: var(--dashboard-routes-wights-shadow);
}

.dashboard-routes-wights-width {
	width: calc(145px * 6);
}

/* sections box shadow */
.section-box-shadow {
	box-shadow: var(--section-shadow);
}

/* question_content_shadow */
.question_content_shadow {
	box-shadow: var(--question_content_shadow);
}

.add-new-section-title-shadow {
	box-shadow: var(-create-program-box-shadow);
}

/* --------------------------------------------------- */

/* handle styles of Tags input component */
.tags_input {
	background-color: #ebebeb;
	font-size: 16px;
}

.tags_input.ub-bg-clr_white {
	background-color: #ebebeb;
}
.tags_input input {
	font-family: "Tajawal", sans-serif;
	font-size: 16px;
	height: auto;
}
.tags_input[aria-activedescendant="TagInput-2"] {
	box-shadow: none !important;
	border: 1px solid #d3d3d3 !important;
}

.ub-dspl_flex strong {
	position: relative;
	min-width: 100px;
	padding: 13px 16px;
	background: #fff;
	font-size: 16px;
	font-weight: 400;
	color: #200407;
	border: 1px solid #d4d4d4;
	border-radius: 15px;
	-webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	-ms-border-radius: 15px;
	-o-border-radius: 15px;
}
.ub-dspl_flex strong:hover {
	opacity: 1;
}

.ub-dspl_flex strong svg {
	height: 14px;
	width: 14px;
	position: absolute;
	left: 1px;
	fill: #c6c6c6;
}

/* handle styles in seo-keywords */
.tags_input.seo-keywords {
	background-color: #9ed1b8;
	padding: 16px 0;
	align-items: flex-start;
}
.tags_input.seo-keywords,
.tags_input.seo-keywords:hover {
	box-shadow: none !important;
	border: none !important;
}

custom-scroll-style::-webkit-scrollbar {
	width: 5px;
}
.nav-bar-menu::-webkit-scrollbar {
	width: 4px;
}
/* .nav-bar-menu::-webkit-scrollbar-thumb {

	background: var(--unnamed-color-ffde06) !important;

} */
/* input#file-upload-button{
background: red !important;
height: 100%;
} */
audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
	display: inline-block;
	vertical-align: middle;
}
/*


 {
   reload ? (
     <Loader />
   ) : (

   )
 }


 */
.svg-prov svg {
	width: 50px;
	height: 50px;
}
